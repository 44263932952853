import React, { useContext } from 'react';

const storageKey = 'bkc-theme';

const prefersDarkMode = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches === true;

export const loadTheme = () => {
  let theme = localStorage.getItem(storageKey);
  if (!theme) theme = theme || prefersDarkMode() ? 'dark' : 'light';
  return theme;
};

export const saveTheme = theme => {
  localStorage.setItem(storageKey, theme);
};

export const ThemeContext = React.createContext({
  theme: 'light',
  updateTheme: theme => {},
});

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const getThemeColors = theme =>
  theme === 'light'
    ? {
        background: 'white',
        headerBackground: '#f8f8f8',
        headerBottomBorder: '#e7e7e7',
        text: 'black',
      }
    : {
        background: 'black',
        headerBackground: '#161616',
        headerBottomBorder: '#393939',
        text: 'white',
      };
