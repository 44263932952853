import React, { useState } from 'react';
import { loadTheme, saveTheme, ThemeContext } from '../util/useTheme';

export const SiteWrapper = ({ children }) => {
  const [theme, setTheme] = useState(loadTheme());

  const updateTheme = newValue => {
    saveTheme(newValue);
    setTheme(newValue);
  };

  return (
    <ThemeContext.Provider value={{ updateTheme, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};
